/* tslint:disable */
/* eslint-disable */
/**
 * mjosenskogService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CoordinatesModel } from '../model';
// @ts-ignore
import { DocumentModel } from '../model';
// @ts-ignore
import { LedgerModel } from '../model';
// @ts-ignore
import { OptionModel } from '../model';
// @ts-ignore
import { OrderConfirmationModel } from '../model';
// @ts-ignore
import { OrderModel } from '../model';
// @ts-ignore
import { OrderStatusEnum } from '../model';
// @ts-ignore
import { OrdersDto } from '../model';
/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get order based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrder: async (accountId: string, orderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('orderGetOrder', 'accountId', accountId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderGetOrder', 'orderId', orderId)
            const localVarPath = `/api/v1/accounts/{accountId}/orders/{orderId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get order confirmation based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrderConfirmation: async (accountId: string, orderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('orderGetOrderConfirmation', 'accountId', accountId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderGetOrderConfirmation', 'orderId', orderId)
            const localVarPath = `/api/v1/accounts/{accountId}/orders/{orderId}/confirmation`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get order coordinates based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrderCoordinates: async (accountId: string, orderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('orderGetOrderCoordinates', 'accountId', accountId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderGetOrderCoordinates', 'orderId', orderId)
            const localVarPath = `/api/v1/accounts/{accountId}/orders/{orderId}/coordinates`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get order documents based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrderDocuments: async (accountId: string, orderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('orderGetOrderDocuments', 'accountId', accountId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderGetOrderDocuments', 'orderId', orderId)
            const localVarPath = `/api/v1/accounts/{accountId}/order/{orderId}/documents`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get order image based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrderImage: async (accountId: string, orderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('orderGetOrderImage', 'accountId', accountId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderGetOrderImage', 'orderId', orderId)
            const localVarPath = `/api/v1/accounts/{accountId}/orders/{orderId}/image`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get invoices based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrderInvoices: async (accountId: string, orderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('orderGetOrderInvoices', 'accountId', accountId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderGetOrderInvoices', 'orderId', orderId)
            const localVarPath = `/api/v1/accounts/{accountId}/orders/{orderId}/invoices`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all orders based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrdersByAccount: async (accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('orderGetOrdersByAccount', 'accountId', accountId)
            const localVarPath = `/api/v1/accounts/{accountId}/orders`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all orders based on account ID and status code.
         * @param {string} accountId 
         * @param {number} statusCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrdersByAccountAndStatus: async (accountId: string, statusCode: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('orderGetOrdersByAccountAndStatus', 'accountId', accountId)
            // verify required parameter 'statusCode' is not null or undefined
            assertParamExists('orderGetOrdersByAccountAndStatus', 'statusCode', statusCode)
            const localVarPath = `/api/v1/accounts/{accountId}/orders/status/{statuscode}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"statusCode"}}`, encodeURIComponent(String(statusCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all orders based on account ID for entrepreneur.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrdersByEntrepreneurAccount: async (accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('orderGetOrdersByEntrepreneurAccount', 'accountId', accountId)
            const localVarPath = `/api/v1/accounts/{accountId}/orders/entrepreneur`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all status codes used in orders.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetStatusCodes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/orders/statusCodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all order types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/orders/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update order based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {OrderModel} [orderModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderUpdateOrder: async (accountId: string, orderId: string, orderModel?: OrderModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('orderUpdateOrder', 'accountId', accountId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderUpdateOrder', 'orderId', orderId)
            const localVarPath = `/api/v1/accounts/{accountId}/orders/{orderId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update order status.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {OrderStatusEnum} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderUpdateOrderStatus: async (accountId: string, orderId: string, status: OrderStatusEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('orderUpdateOrderStatus', 'accountId', accountId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderUpdateOrderStatus', 'orderId', orderId)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('orderUpdateOrderStatus', 'status', status)
            const localVarPath = `/api/v1/accounts/{accountId}/orders/{orderId}/status/{status}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update order team.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderUpdateOrderTeam: async (accountId: string, orderId: string, teamId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('orderUpdateOrderTeam', 'accountId', accountId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderUpdateOrderTeam', 'orderId', orderId)
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('orderUpdateOrderTeam', 'teamId', teamId)
            const localVarPath = `/api/v1/accounts/{accountId}/orders/{orderId}/team/{teamId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload file based on account ID and order ID
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderUploadOrderDocument: async (accountId: string, orderId: string, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('orderUploadOrderDocument', 'accountId', accountId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderUploadOrderDocument', 'orderId', orderId)
            const localVarPath = `/api/v1/accounts/{accountId}/orders/{orderId}/documents`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get order based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderGetOrder(accountId: string, orderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderGetOrder(accountId, orderId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrderApi.orderGetOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get order confirmation based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderGetOrderConfirmation(accountId: string, orderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderConfirmationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderGetOrderConfirmation(accountId, orderId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrderApi.orderGetOrderConfirmation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get order coordinates based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderGetOrderCoordinates(accountId: string, orderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoordinatesModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderGetOrderCoordinates(accountId, orderId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrderApi.orderGetOrderCoordinates']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get order documents based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderGetOrderDocuments(accountId: string, orderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderGetOrderDocuments(accountId, orderId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrderApi.orderGetOrderDocuments']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get order image based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderGetOrderImage(accountId: string, orderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderGetOrderImage(accountId, orderId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrderApi.orderGetOrderImage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get invoices based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderGetOrderInvoices(accountId: string, orderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LedgerModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderGetOrderInvoices(accountId, orderId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrderApi.orderGetOrderInvoices']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all orders based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderGetOrdersByAccount(accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrdersDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderGetOrdersByAccount(accountId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrderApi.orderGetOrdersByAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all orders based on account ID and status code.
         * @param {string} accountId 
         * @param {number} statusCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderGetOrdersByAccountAndStatus(accountId: string, statusCode: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderGetOrdersByAccountAndStatus(accountId, statusCode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrderApi.orderGetOrdersByAccountAndStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all orders based on account ID for entrepreneur.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderGetOrdersByEntrepreneurAccount(accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrdersDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderGetOrdersByEntrepreneurAccount(accountId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrderApi.orderGetOrdersByEntrepreneurAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all status codes used in orders.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderGetStatusCodes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderGetStatusCodes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrderApi.orderGetStatusCodes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all order types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderGetTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderGetTypes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrderApi.orderGetTypes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update order based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {OrderModel} [orderModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderUpdateOrder(accountId: string, orderId: string, orderModel?: OrderModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderUpdateOrder(accountId, orderId, orderModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrderApi.orderUpdateOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update order status.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {OrderStatusEnum} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderUpdateOrderStatus(accountId: string, orderId: string, status: OrderStatusEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderUpdateOrderStatus(accountId, orderId, status, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrderApi.orderUpdateOrderStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update order team.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderUpdateOrderTeam(accountId: string, orderId: string, teamId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderUpdateOrderTeam(accountId, orderId, teamId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrderApi.orderUpdateOrderTeam']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Upload file based on account ID and order ID
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderUploadOrderDocument(accountId: string, orderId: string, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderUploadOrderDocument(accountId, orderId, file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrderApi.orderUploadOrderDocument']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * 
         * @summary Get order based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrder(accountId: string, orderId: string, options?: any): AxiosPromise<OrderModel> {
            return localVarFp.orderGetOrder(accountId, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get order confirmation based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrderConfirmation(accountId: string, orderId: string, options?: any): AxiosPromise<OrderConfirmationModel> {
            return localVarFp.orderGetOrderConfirmation(accountId, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get order coordinates based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrderCoordinates(accountId: string, orderId: string, options?: any): AxiosPromise<CoordinatesModel> {
            return localVarFp.orderGetOrderCoordinates(accountId, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get order documents based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrderDocuments(accountId: string, orderId: string, options?: any): AxiosPromise<Array<DocumentModel>> {
            return localVarFp.orderGetOrderDocuments(accountId, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get order image based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrderImage(accountId: string, orderId: string, options?: any): AxiosPromise<string> {
            return localVarFp.orderGetOrderImage(accountId, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get invoices based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrderInvoices(accountId: string, orderId: string, options?: any): AxiosPromise<Array<LedgerModel>> {
            return localVarFp.orderGetOrderInvoices(accountId, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all orders based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrdersByAccount(accountId: string, options?: any): AxiosPromise<Array<OrdersDto>> {
            return localVarFp.orderGetOrdersByAccount(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all orders based on account ID and status code.
         * @param {string} accountId 
         * @param {number} statusCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrdersByAccountAndStatus(accountId: string, statusCode: number, options?: any): AxiosPromise<Array<OrderModel>> {
            return localVarFp.orderGetOrdersByAccountAndStatus(accountId, statusCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all orders based on account ID for entrepreneur.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetOrdersByEntrepreneurAccount(accountId: string, options?: any): AxiosPromise<Array<OrdersDto>> {
            return localVarFp.orderGetOrdersByEntrepreneurAccount(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all status codes used in orders.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetStatusCodes(options?: any): AxiosPromise<Array<OptionModel>> {
            return localVarFp.orderGetStatusCodes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all order types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGetTypes(options?: any): AxiosPromise<Array<OptionModel>> {
            return localVarFp.orderGetTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update order based on account ID and order ID.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {OrderModel} [orderModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderUpdateOrder(accountId: string, orderId: string, orderModel?: OrderModel, options?: any): AxiosPromise<OrderModel> {
            return localVarFp.orderUpdateOrder(accountId, orderId, orderModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update order status.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {OrderStatusEnum} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderUpdateOrderStatus(accountId: string, orderId: string, status: OrderStatusEnum, options?: any): AxiosPromise<OrderModel> {
            return localVarFp.orderUpdateOrderStatus(accountId, orderId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update order team.
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderUpdateOrderTeam(accountId: string, orderId: string, teamId: string, options?: any): AxiosPromise<OrderModel> {
            return localVarFp.orderUpdateOrderTeam(accountId, orderId, teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload file based on account ID and order ID
         * @param {string} accountId 
         * @param {string} orderId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderUploadOrderDocument(accountId: string, orderId: string, file?: File, options?: any): AxiosPromise<DocumentModel> {
            return localVarFp.orderUploadOrderDocument(accountId, orderId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @summary Get order based on account ID and order ID.
     * @param {string} accountId 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderGetOrder(accountId: string, orderId: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderGetOrder(accountId, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get order confirmation based on account ID and order ID.
     * @param {string} accountId 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderGetOrderConfirmation(accountId: string, orderId: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderGetOrderConfirmation(accountId, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get order coordinates based on account ID and order ID.
     * @param {string} accountId 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderGetOrderCoordinates(accountId: string, orderId: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderGetOrderCoordinates(accountId, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get order documents based on account ID and order ID.
     * @param {string} accountId 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderGetOrderDocuments(accountId: string, orderId: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderGetOrderDocuments(accountId, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get order image based on account ID and order ID.
     * @param {string} accountId 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderGetOrderImage(accountId: string, orderId: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderGetOrderImage(accountId, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get invoices based on account ID and order ID.
     * @param {string} accountId 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderGetOrderInvoices(accountId: string, orderId: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderGetOrderInvoices(accountId, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all orders based on account ID.
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderGetOrdersByAccount(accountId: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderGetOrdersByAccount(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all orders based on account ID and status code.
     * @param {string} accountId 
     * @param {number} statusCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderGetOrdersByAccountAndStatus(accountId: string, statusCode: number, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderGetOrdersByAccountAndStatus(accountId, statusCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all orders based on account ID for entrepreneur.
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderGetOrdersByEntrepreneurAccount(accountId: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderGetOrdersByEntrepreneurAccount(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all status codes used in orders.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderGetStatusCodes(options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderGetStatusCodes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all order types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderGetTypes(options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderGetTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update order based on account ID and order ID.
     * @param {string} accountId 
     * @param {string} orderId 
     * @param {OrderModel} [orderModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderUpdateOrder(accountId: string, orderId: string, orderModel?: OrderModel, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderUpdateOrder(accountId, orderId, orderModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update order status.
     * @param {string} accountId 
     * @param {string} orderId 
     * @param {OrderStatusEnum} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderUpdateOrderStatus(accountId: string, orderId: string, status: OrderStatusEnum, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderUpdateOrderStatus(accountId, orderId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update order team.
     * @param {string} accountId 
     * @param {string} orderId 
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderUpdateOrderTeam(accountId: string, orderId: string, teamId: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderUpdateOrderTeam(accountId, orderId, teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload file based on account ID and order ID
     * @param {string} accountId 
     * @param {string} orderId 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderUploadOrderDocument(accountId: string, orderId: string, file?: File, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderUploadOrderDocument(accountId, orderId, file, options).then((request) => request(this.axios, this.basePath));
    }
}

